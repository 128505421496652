import React from "react";
import { Link } from "react-router-dom";

export const About: React.FC = () => {
  return (
    <div className="about-container">
      <h1>とうらぶ練度チェッカーについて</h1>
      <p>
        初めまして。
        <br /> このサイトの運営者の
        <a href="https://twitter.com/hiyoko_coder">ぴよぱんまん</a>と申します。
        <br />
        とうらぶ練度チェッカーは、共に過ごしてきた刀剣男士たちの成長を振り返りたいという思いと他刃に追いつくために根兵糖を頬張ったり厚樫山周回したりする刀剣男士の２次創作を見たいという下心から作られたサービスです。
        <br />
        ぜひ皆さまも自分の本丸の男士と共に過ごした軌跡を振り返ってみてください。
        <br />
        また、調査結果の「厚樫山○周分」についてですが、こちらは対象の男士が隊長で誉なし・1回の出陣で5回の戦闘を行った際に得られる経験値（600
        * 5）で算出しております。
        <br />
        何か不備がございましたら、<Link to="/contact">お問い合わせページ</Link>
        をご覧になった上でご連絡をいただければと思います。
        <br />
        また、現在調査できる刀剣男士の種類は限られていますが、このサービスに需要があれば他の男士たちなども追加する予定です。(こちらも追加して欲しい男士がいればお問い合わせまでお願いいたします！)
        <br />
        ここまで読んでいただいてありがとうございました。
        <br />
        皆さまの本丸でのハッピーライフを願っています。
      </p>
    </div>
  );
};
